/* App.css */

.App {
  display: flex;
  flex-direction: column;
}

.image-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.element {
  width: 85%;
}

.text-container {
  padding-top: 60px;
  padding-bottom: 80px;
  padding-right: 150px;
  text-align: right;
}

.text {
  font-family: "Inter", Helvetica;
  background-color: beige;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.subtitle-home {
  font-size: 12px;
  font-family: "Inter", Helvetica;
  padding-top: 10px;
  padding-bottom: 20px;
}

.button {
  font-family: "Inter", Helvetica;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 5px;
  margin-top: 10px;
  text-decoration: none;
  background-color: #007bff; 
  background-image: none; 
  transition: background-color 0.3s ease-in-out; 
}

.button:hover {
  background-color: #2ecc71;
}

