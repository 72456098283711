/* CSS Reset */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  border: none; 
}

.box {
  height: 12vh;
  width: 100%;
  position: relative;
}

.box .header {
  background-color: transparent;
  height: 12vh;
  position: absolute;
  width: 100%;
  border-bottom: 1px solid #000000;
}

.box .overlap-group {
  background-color: #ffffff;
  height: 100%;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box .text-wrapper,
.box .text-wrapper-1,
.box .text-wrapper-3,
.box .text-wrapper-2 {
  color: #1e1e1e;
  font-family: "Inter-Medium", Helvetica;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: color 0.3s ease; 
}

.box .text-wrapper {
  left: 55%;
}

.box .text-wrapper-1 {
  left: 65%;
}

.box .text-wrapper-2 {
  left: 75%;
}

.box .text-wrapper-3 {
  left: 85%;
}

.box .logo {
  height: 12vh;
  width: auto;
  left: 10%;
  position: absolute;
  top: 0;
}

.text-wrapper,
.text-wrapper-1,
.text-wrapper-3,
.text-wrapper-2 {
  color: black;
  text-decoration: none; 
  transition: color 0.3s ease;
}


.text-wrapper:hover,
.text-wrapper-1:hover,
.text-wrapper-3:hover,
.text-wrapper-2:hover {
  color: green; 
}

.mission-vision{
  padding-left: 200px;
}
