.contact-container {
    padding: 0 140px;
    background-color: #F1F1F1;
    padding-top: 20px ; 
    padding-bottom: 50px ; 

  }
  
  .contact-form {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    font-family: 'Inter', sans-serif;
    margin-top: 50px;
    padding: 30px;
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); 
    border-radius: 10px; 
    margin-bottom: 100px;
  }
  
  .contact-page-title {
    font-size: 30px;
    font-family: "Inter", Helvetica;
    text-align: center;
    padding: 20px;
    margin-top: 30px;
    font-weight: bold; 
  }
  
  .contact-row {
    display: flex;
    justify-content: center; 
    flex-wrap: wrap; 
    margin: 0 auto; 
    max-width: 1000px;
  }
  
  .contact-box {
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); 
    border-radius: 10px; 
    padding: 20px;
    margin: 20px; 
    width: 240px; 
    height: 160px;
    flex-direction: column;
    transition: background-color 0.3s, color 0.3s;
    overflow: auto; 
    white-space: normal; 
    word-wrap: break-word; 
  }
  
  .contact-logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .contact-logo img {
    max-width: 30px;
    max-height: 30px;
    margin-bottom: 5px;
    margin-top: 10px;
  }
  
  .contact-title {
    margin-bottom: 20px;
    text-align: center;
    font-family: "Inter-Bold";
  }
  
  .contact-title h2 {
    text-align: center;
    font-size: 20px;
    margin-bottom: -10px;
  }
  
  .contact-subtitle {
    margin-top: 0;
    text-align: center;
    font-size: 12px;
    font-family: "Inter", Helvetica;

  }
  
  .contact-box .contact-title,
  .contact-box .contact-subtitle {
    line-height: 1.0; 
  }
  
  .contact-form-field {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="email"],
  select,
  button {
    width: 100%;
    padding: 8px;
    font-size: 12px;
  }
  
  button {
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    padding: 6px;
    width: 120px;
    font-size: 14px;
    border-radius: 5px;
    margin: 0 auto;
    align-self: auto;
  }
  
  p {
    margin-top: 20px;
  }
  
  .contact-page-title {
    text-align: center;
    padding-bottom: 15px;
    display: block;
  }
  
  .contact-info {
    font-style: italic;
    font-size: 14px;
    text-align: center;
  }
  
  .contact-form-row.special-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .contact-form-row.special-row .contact-form-field {
    width: 48%;
  }
  
  .contact-form-row.special-row .contact-form-field:last-child {
    margin-left: 4%;
  }

  .contact-form-input{
    font-family: "Inter", Helvetica;
    width: 100%;
    padding: 8px;
    font-size: 12px;
    height: 100px; /* Set the height to a fixed value */
  }
  p {
    font-weight: normal;
    margin-top: 20px;
}

.required {
  color: red;
}

/* Styling for the active and inactive buttons */
.activeButton {
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.inactiveButton {
  background-color: gray;
  color: white;
  cursor: not-allowed;
}